<template>
  <div>
    <a-spin :spinning="spinning" size="large">
      <search-form :items="items" @fnSearch="handleFnSearch" />
      <grid
        ref="grid"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
      >
        <template #number="{ record }">
          <div class="iss-user">
            <span>{{ record.number }}</span>
            <a-tooltip title="查看成员" placement="right">
              <a-button
                type="primary"
                size="small"
                shape="circle"
                ghost
                @click="handleClickViewMember(record)"
              >
                <template #icon><UserOutlined /></template>
              </a-button>
            </a-tooltip>
          </div>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </a-spin>
    <a-modal
      :visible="visible"
      title="分群成员列表"
      :width="700"
      :footer="null"
      :maskClosable="false"
      @cancel="visible = false"
      :afterClose="handleAfterClose"
    >
      <user :group-id="groupId" />
    </a-modal>
  </div>
</template>

<script>
import { Tooltip, Spin } from 'ant-design-vue';
import { UserOutlined } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import User from './components/user';
import Clustering from '@/api/clustering.js';

export default {
  name: 'group',
  components: {
    ATooltip: Tooltip,
    ASpin: Spin,
    SearchForm,
    Grid,
    Operation,
    User,
    UserOutlined,
  },
  data() {
    return {
      spinning: false,
      items: [{ key: 'name', label: '名称' }],
      columns: [
        { dataIndex: 'name', title: '名称' },
        {
          key: 'number',
          title: '客户数量',
          slots: { customRender: 'number' },
        },
        { dataIndex: 'remark', title: '备注' },
        {
          key: 'id',
          title: '操作',
          width: 220,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'group:add',
          fnClick: this.edit,
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'group:update',
          fnClick: this.edit,
        },
        {
          type: 'exec',
          icon: 'ToolTwoTone',
          permission: 'group:exec',
          label: '执行',
          fnClick: this.exec,
        },
        {
          type: 'delete',
          permission: 'group:delete',
          fnClick: this.delete,
        },
      ],
      url: Clustering.pageUrl,
      search: {},
      visible: false,
      groupId: '',
      loading: false,
    };
  },

  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    handleAfterClose() {
      this.groupId = '';
    },
    handleClickViewMember(data) {
      this.visible = true;
      this.groupId = data.id;
    },
    edit(data) {
      this.$router.push(`/datacenter/group/${data.id || 'add'}`);
    },
    delete(data) {
      Clustering.delete('group:delete', { ids: [data.id] }).then(() => {
        this.$message.success(this.$t('tips.deleteSuccess'));
        this.$refs.grid.refreshGrid();
      });
    },
    exec(data) {
      this.spinning = true;
      Clustering.runGroupRule('group:exec', data.id)
        .then(() => {
          this.$message.success('群组执行成功！');
          this.$refs.grid.refreshGrid();
        })
        .finally(() => {
          this.spinning = false;
        });
    },
  },
};
</script>

<style scoped>
.iss-user .ant-btn-icon-only.ant-btn-sm {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
}
</style>
